@import '../../../styles/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    font-family: $font-family-sans-serif;
}

.home {
    overflow: hidden;
    min-height: 100vh;
    padding: 10rem 0 0 7%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.home video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    filter: blur(3px) brightness(0.6);
}
.home-content {
    flex: 1;
    padding-right: 2rem;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.home-content h3 {
    font-size: 3.6rem;
    font-weight: 500;
    color: $secondary;
}
.home-content span{
    background: linear-gradient(267deg,rgb(181, 221, 70) 0.36%,green 102.06%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.home-content p{
    font-size: $h3-font-size;
    font-weight: 600;
    margin-top: 2rem;
    color: $secondary;
}
.btn2{
    cursor: pointer;
    background-color: #ffffff20;
    color: $secondary;
    font-size: $h4-font-size;
    padding: 10px 20px;
    border-radius: 50px;
    margin-top: 1.5rem;
}
.icon{
    margin-left: 10px;
}
@media (max-width:600px){
    .home{
        padding: 8rem 0;
    }
    .home-content h3{
    font-size: 3rem;
    }
   .home-content p{
    font-size: 20px;
   }
}
@media (max-width:450px){
    .home{
        min-height: 100vh;
        padding: 8rem 0;
    }
    .home-content h3{
        font-size: 2.4rem;
        }
       .home-content p{
        font-size: 18px;
       }
}