@import '../../../styles/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
}

/* Background & Layout */
.design-development-approach {
  padding: 40px 5px;
  background: linear-gradient(120deg, #0b6623, sandybrown);
  text-align: center;
  overflow: hidden;

  h2 {
    font-size: 28px;
    font-weight: 400;
    color: white;
  }
  
  h3 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
    color: white;
  }
}

/* Development Boxes Grid */
.development-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 40px;
  padding: 0 1rem;
}

/* Glassmorphism Box Effect */
.development-box {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  padding: 1rem;
  border-radius: 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  transform-style: preserve-3d;
}

/* Hover Effect for 3D Lift */
.development-box:hover {
  transform: perspective(1000px) rotateX(5deg) rotateY(5deg) scale(1.05);
  box-shadow: 0 10px 30px rgba(34, 139, 34, 0.5);
}

/* Icon Container */
.icon-container {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Icon Image */
.icon-container img {
  width: 30px;
  height: 30px;
}

/* Box Headings */
.development-box h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
}

/* Box Descriptions */
.development-box p {
  font-size: 14px;
  color: white;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  h2 { font-size: 25px; }
  h3 { font-size: 23px; }
  .development-container { padding: 0 1rem; }
  .development-box { padding: 1.5rem; }
}

@media (max-width: 480px) {
  h2 { font-size: 22px; }
  h3 { font-size: 20px; }
  .development-box { padding: 1.2rem; }
}
