/*******************/
// Theme Fonts
/********************/
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
$font-family-sans-serif: 'Poppins', sans-serif;
/*******************/
// Theme Colors
/********************/
$primary: rgb(159, 241, 159);
$info: black;
$secondary: #e6eacb;
$danger: #dd0707;
$success: #00c292;
$warning: #fec107;
$purple: #ab8ce4;
$cyan: #01c0c8;

// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #4f5467 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$text-muted: $gray-600 !default;

$theme-colors: (
  'primary': $primary,
  'secondary': $gray-100,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'purple': $purple,
  'cyan': $cyan,
  'light': $gray-100,
  'dark': $gray-800,
  'gray-700': $gray-700,
  'light-primary': #ffddd3,
  'light-success': #d5f3f2,
  'light-info': #d3edfa,
  'light-warning': #f8ecdc,
  'light-danger': #f8dddd,
);

/*******************/
// Theme Text Contrast
/********************/
$min-contrast-ratio: 2;
//$enable-shadows: true !default;

/*******************/
// Theme body bg & text color
/********************/
$body-bg: #edf1f5 !default;
$body-color: $gray-900 !default;

// heading fonts setting
$h1-font-size: 36px !default;
$h2-font-size: 30px !default;
$h3-font-size: 24px !default;
$h4-font-size: 18px !default;
$h5-font-size: 16px !default;
$h6-font-size: 14px !default;

/*******************/
// Theme common variable
/********************/
$border-radius: 0.3rem !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.05);

$headings-color: inherit !default;
$headings-font-weight: 300 !default;
$headings-margin-bottom: 8px !default;

$font-size-base: 0.875rem;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

$card-border-width: 0 !default;
$card-border-radius: 0 !default;
$card-box-shadow: $box-shadow !default;
$grid-gutter-width: 20px !default;
$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;

$input-bg: $white !default;

// table
$table-cell-padding-y: 1rem;
$table-cell-padding-x: 1rem;
$table-hover-bg: $gray-100;

// Progress bars
$progress-height: 6px !default;

/*******************/
// Theme btn
/********************/
$btn-focus-width: 0px;
$btn-box-shadow: 0 !default;
$btn-focus-box-shadow: 0;

// badge
$badge-font-weight: 400;
$badge-padding-y: 4px;
$badge-padding-x: 9px;

/*******************/
// Theme sidebar width
/********************/
$sidebarWidth: 220px;
$sidebartext: $gray-900;
$sidebartextlight: rgba(30, 42, 53, 0.5);
$miniSidebarWidth: 80px;

$topbarHeight: 65px;

$dropdown-border-width: 0 !default;
$dropdown-box-shadow: $box-shadow !default;
$dropdown-link-hover-bg: $gray-100 !default;

$enable-negative-margins: true;
