.milestones {
    text-align: center;
    padding: 2rem;
    background: linear-gradient(to bottom, #dfffd8, #ffffff);
  }
  
  .milestones-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 2rem 0;
}

/* Glassmorphism Effect for Milestones */
.milestone {
    flex: 1;
    max-width: 200px;
    margin: 1rem;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    padding: 1rem;
    border-radius: 15px;
    text-align: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transform-style: preserve-3d;
}

/* 3D Hover Effect */
.milestone:hover {
    transform: perspective(1000px) rotateX(5deg) rotateY(5deg) scale(1.05);
    box-shadow: 0 10px 30px rgba(34, 139, 34, 0.5);
}
  
  .milestone h3 {
    font-size: 2rem;
    color: #77c043;
  }
  
  .milestone p {
    font-size: 1rem;
    color: #333;
  }
  
  .cta {
    margin-top: 2rem;
  }
  
  .cta h3 {
    font-size: 1.5rem;
  }
  
  .cta button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background: #ffc107;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  
  /* Footer Section */
  .footer {
    display: flex;
    align-items: center;
    background: #f3f3f3;
    padding: 2rem;
  }
  
  .footer-logo {
    max-width: 50%;
    text-align: center;
    margin-bottom: 2rem;
  }

  .logo img{
    height: 70px;
    width: 70px;
    margin-left: 20px;
} 
  .footer-columns {
    display: flex;
    flex-wrap: wrap;
  }
  
  .footer-column {
    flex: 1;
    max-width: 300px;
    margin: 1rem;
  }
  
  .footer-column h4 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .footer-column ul {
    list-style: none;
  }
  
  .footer-column li {
    margin-bottom: 0.5rem;
    color: #777;
  }

  .contact-info {
    display: flex;
    flex-direction: row;
  }
  
  .contact-info form {
    margin-top: 1rem;
  }
  
  .contact-info input {
    padding: 0.5rem;
    margin-right: 0.5rem;
    border: 1px solid #ccc;
  }
  
  .contact-info button {
    padding: 0.5rem 1rem;
    background: #ffc107;
    color: white;
    border: none;
    cursor: pointer;
  }
  .social-icons {
    margin-top: 20px;
  
    .social-icon {
      font-size: 1.5rem;
      color: #fff;
      background-color: black;
      margin-left: 2rem;
      border: 2px solid black; /* Border around the icon */
      border-radius: 50%; /* Makes the border circular */
      transition: all 0.3s;
      padding: 5px 12px;
    }
  }

  @media (max-width: 768px) {
    .footer {
      display: flex;
      flex-direction: column;
    }
    
    .footer-logo {
      max-width: 100%;
    }

    .social-icons {    
      .social-icon {
        font-size: 1rem;
        margin-left: 15px;
        padding: 3px 10px;
      }
    }
  
  }
  
  
  /* Responsive Design */
  @media (max-width: 630px) {
    p{
      font-size: 12px;
    }
    .milestones-container,
    .footer-columns {
      flex-direction: column;
      align-items: center;
    }
  
    .milestone,
    .footer-column {
      max-width: 100%;
    }
  }


  #back-to-top {
    position: fixed;
    bottom: 20px; /* Adjust this value to move the button higher or lower */
    right: 20px; /* Adjust this value to move the button closer or farther from the right edge */
    z-index: 1000; /* Ensures the button stays above other elements */
    background-color: #77c043; /* Change this to your desired background color */
    color: white; /* Icon color */
    border: none;
    padding: 10px 13px;
    border-radius: 50%; /* Makes it circular */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds a soft shadow */
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth hover effects */
  }
  
  #back-to-top:hover {
    background-color: #77c043; /* Darker shade on hover */
    transform: scale(1.1); /* Slight zoom effect */
  }
  
  #back-to-top .fea {
    font-size: 1.5rem; /* Adjust icon size */
  }
  