@import '../../../styles/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
}

/* Background Theme - Green & SandyBrown */
.services {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem 1.5rem;
    background: linear-gradient(120deg, #05611e, #04b534); 
    color: white;
}

/* Main Headings */
.services .heading1 {
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    color: #ffffff; 
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.services .heading2 {
    font-size: 2.5rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 3rem;
    color: #ffffff; 
}

/* Gradient Highlight on "Expertise" */
.services span {
    background: white;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0 5px;
    font-weight: 700;
}

/* Services Container */
.services-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
    max-width: 1200px;
}

/* Glassmorphism Card Effect */
.services-container .services-box {
    flex: 1 1 calc(33.333% - 2rem);
    max-width: 320px;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    padding: 2rem;
    border-radius: 15px;
    text-align: center;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transform-style: preserve-3d;
    transform: perspective(1000px) rotateX(0) rotateY(0);
}

/* Hover Effect with Soft Green Glow */
.services-container .services-box:hover {
    transform: perspective(1000px) rotateX(5deg) rotateY(5deg) scale(1.07);
    box-shadow: 0 10px 30px rgba(34, 139, 34, 0.5);
}

/* Service Titles */
.services-box h3 {
    font-size: 1.4rem;
    font-weight: 600;
    color: #ffffff;
    margin: 0.5rem 0;
}

/* Responsive Design */
@media (max-width: 1000px) {
    .services-box h3 {
        font-size: 1.2rem;
    }
    .services-container .services-box {
        flex: 1 1 calc(50% - 2rem);
    }
}

@media (max-width: 768px) {
    .services-container {
        gap: 1rem;
    }
    .services-box h3 {
        font-size: 1.1rem;
    }
    .services-container .services-box {
        flex: 1 1 calc(100% - 1rem);
    }
    .services .heading1,
    .services .heading2 {
        font-size: 2rem;
    }
}

@media (max-width: 480px) {
    .services .heading1,
    .services .heading2 {
        font-size: 1.5rem;
    }
    .services-box h3 {
        font-size: 1rem;
    }
}
