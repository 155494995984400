@import '../../../styles/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
}

.gallery {
  padding: 6rem 0;
  background: linear-gradient(to bottom, #dfffd8, #ffffff);
  color: black;
  position: relative;
  overflow: hidden;

  .heading {
    text-align: center;
    margin-bottom: 2rem;

    h1 {
      font-size: 50px;
      font-weight: 600;
      letter-spacing: 2px;
      color: black;
    }
  }

  .photo-section-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    padding: 2rem 0;
  }

  .photo-section {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;

    .photo-track {
      display: flex;
      gap: 35px;
      display: flex;
      animation: scroll 35s linear infinite;

      img {
        height: 300px;
        width: 350px;
        border-radius: 15px;
        transition: transform 0.3s ease-in-out;
      }
    }
  }

  @keyframes scroll {
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); }
  }
}
