/* Container */
.goals-screen {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6rem 0 2rem 0;
  overflow: hidden; /* Ensures no scrollbars from background overflow */
}

/* Video Styling */
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100;
  height: 100%;
  object-fit: cover;
  z-index: -2; /* Behind everything else */
}

/* Overlay Styling */
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #05611e10; /* Light green with transparency */
  z-index: -1; /* Above the video but behind the content */
}
/* Header Section */
.goals-header {
  text-align: center;
  margin-bottom: 20px;
}

.goals-header h1 {
  font-size: 3rem;
  font-weight: bold;
  color: #fff;
  margin: 0;
}

.goals-header h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  margin-top: 10px;
}

/* Goals Content */
.goals-content {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
}

.goals-content p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #fff;
  margin-bottom: 20px;
}

.goals-mission-vision {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.mission,
.vision {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.mission h3,
.vision h3 {
  color: #4caf50;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.mission p,
.vision p {
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
}

/* Icons Section */
.goals-icons {
  text-align: center;
  margin-top: 30px;
}

.goals-icons img {
  width: 90%;
  max-width: 800px;
  height: auto;
  border-radius: 10px;
}

/* Responsive Design */
@media (min-width: 768px) {
  .goals-mission-vision {
    flex-direction: row;
    justify-content: space-between;
  }

  .mission,
  .vision {
    width: 48%;
  }
}
@media (max-width: 430px) {
  .goals-header h1 {
      font-size: 35px;
  }
  .goals-header h2 {
      font-size: 30px;
  }
}





