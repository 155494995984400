@import '../../../styles/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    justify-content: space-between;
    font-family: $font-family-sans-serif;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // padding: 1rem 0;
    display: flex;
    align-items: center;
    z-index: 100;
    background-color: $secondary;
}

.header.sticky {
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.header-div {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.logo img{
    height: 50px;
    min-width: 130px;
    padding: 5px;
    margin-left: 20px;
}
.mobile-menu{
    gap: 4rem;
    display: flex;
}
.navbar a {
    font-size: $h4-font-size;
    color: $info;
}

.navbar a:hover,
.navbar a.active {
    color: $info; 
}

#menu-icon {
    display: none;
    align-self: flex-end;
}
.btn{
    border-radius: 5px;
    background: linear-gradient(267deg,rgb(109, 240, 109) 0.36%,rgb(33, 179, 33) 80.06%);
    color: $secondary;
    font-size: $h4-font-size;
    cursor: pointer;
    padding: 8px 12px;
    width: fit-content;
    height: fit-content;
    border: none;
    margin-right: 30px
}
@media (max-width: 1100px) {
    .navbar a {
        font-size: $h5-font-size;
    }
    .mobile-menu{
        gap: 2rem;
    }
    .btn {
        font-size: $h5-font-size;
        padding: 5px 10px;
    }
}

@media (max-width: 860px) {
    #menu-icon {
        display: block;
        color: black;
        font-size: 30px;
        padding: 20px 10px;
    }

    .navbar {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: $secondary;
        gap: 1rem;
        padding: 1rem;
        z-index: 99;

        &.active {
            display: flex;
        }
    }

    .btn {
        margin-top: 1rem;
    }
}
@media (max-width: 600px) {
     .navbar a{
        font-size: 13px;
     }
     .btn{
        font-size: 13px;
     }
}
@media (max-width: 530px) {
    .navbar a{
        font-size: 11px;
     }
     .mobile-menu{
        gap: 5px;
     }
}