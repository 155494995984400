@import "../../../styles/_variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

/* Our Team Section */
.ourTeam {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 2rem;
  background: #ffffff;
  color: black;
  overflow: hidden;
}

/* Headings */
.heading {
  text-align: center;
  margin-bottom: 3rem;

  h1 {
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: black;
  }

  h2 {
    font-size: 1.8rem;
    font-weight: 500;
    color: black;
    opacity: 0.7;
  }
}

/* Image Container */
.images-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
}

/* Glassmorphism Card Effect */
.image {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
}

.image img {
  width: 250px;
  border-radius: 15px;
  object-fit: cover;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
  cursor: pointer;
}

/* 3D Hover Effects */
.image img:hover {
  transform: perspective(1000px) rotateX(5deg) rotateY(5deg) scale(1.05);
}

/* Responsive Design */
@media (max-width: 1000px) {
  .heading h1 {
    font-size: 2.5rem;
  }
  
  .heading h2 {
    font-size: 1.5rem;
  }

  .image img {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 768px) {
  .heading h1 {
    font-size: 2rem;
  }
  
  .heading h2 {
    font-size: 1.3rem;
  }

  .image img {
    width: 180px;
    height: 180px;
  }
}

@media (max-width: 480px) {
  .heading h1 {
    font-size: 1.8rem;
  }
  
  .heading h2 {
    font-size: 1.2rem;
  }

  .image img {
    width: 350px;
    height: 350px;
  }
}
