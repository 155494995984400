@import '../../../styles/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
}

/* Background Theme */
.portfolio {
  background: white;
  color: black;
  position: relative;
  overflow: hidden;
}

/* Headings */
.heading-portfolio {
  text-align: center;

  h2 {
    margin-top: 30px;
    font-size: 30px;
    font-weight: 600;
    color: black;
  }
}

/* Partners Wrapper */
.partners-section-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

/* Partners Scrolling Section */
.partners-section {
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;

  .partners-track {
    display: flex;
    gap: 35px;
    animation: scroll 35s linear infinite;

    img {
      height: 100px;
      width: auto;
      border-radius: 15px;
      transition: transform 0.3s ease-in-out;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      padding: 1rem;
    }
  }
}

/* Infinite Scroll Animation */
@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

/* Responsive Design */
@media (max-width: 1000px) {
  .heading-portfolio h1 { font-size: 2.5rem; }
  .heading-portfolio h2 { font-size: 2rem; }
  .partners-section {
    .partners-track {
      img {
        height: 80px;
      }
    }
  }
}

@media (max-width: 768px) {
  .heading-portfolio h1 { font-size: 2rem; }
  .heading-portfolio h2 { font-size: 1.8rem; }
  .partners-section {
    .partners-track {
      img {
        height: 80px;
      }
    }
  }
}

@media (max-width: 480px) {
  .heading-portfolio h1 { font-size: 1.8rem; }
  .heading-portfolio h2 { font-size: 1rem; margin-bottom: 20px;}
  .partners-section {
    .partners-track {
      img {
        height: 70px;
      }
    }
  }
}
