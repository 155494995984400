@import '../../../styles/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    font-family: 'Poppins', 'sans-serif';
}
.about {
    justify-content: center;
    padding: 6rem 0 2rem 0;
    background: linear-gradient(to bottom, #dfffd8, #dfffd8);
}
.about-content{
    display: flex;
    align-self: center;
    justify-content: center;
    flex-direction: row;
    gap: 80px;
    padding: 30px 50px;
}
.about .heading {
    font-weight: 500;
    font-size: 45px;
    justify-self: center;
    animation-duration: 3s;
    animation-name: slidein;
    height: fit-content;
    width: fit-content; 
}
.about .heading span{
    font-weight: 500;
    background: linear-gradient(267deg,rgb(181, 221, 70) 0.36%,green 102.06%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.image-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    width: fit-content;
    margin-top: 100px;
}
.image-container .big-image{
    width: 30vw;
    height: 20vw;
    object-fit: cover;  
}
.about-right {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: fit-content;
    gap: 10px;
}
.about-right p{
    margin-top: 30px;
    height: fit-content;
    width: fit-content;
    font-size: 25px;
    font-weight: 600;
}
.about-right ul{
    display: flex;
    flex-direction: column;
    gap: 13px
}
.about-right ul li{
    line-height: 2rem;
    font-size: 18px;
}
.bottom-content{
    display: flex;
    flex-direction: row;
    padding: 150px;
    position: relative;
    overflow: hidden;
}

.bottom-content video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(3px) brightness(0.6);
  }
  
  .about-bottom-left,
.about-bottom-right {
  position: relative;
  z-index: 1; /* Ensure text and buttons appear above the video */
}

.about-bottom-left .first{
    font-weight: 100;
    font-size: $h1-font-size;
    color: white;
}
.about-bottom-left .second{
    font-size: $h1-font-size;
    color: white;
}
.about-bottom-left span{
    color: pink !important;
    padding: 0 5px ;
    font-weight: 600;
}
.btn3{
    background-color: #ffffff20;
    color: $secondary;
    font-size: $h4-font-size;
    padding: 10px 20px;
    border-radius: 50px;
    margin-top: 1.5rem;
}
@media (max-width:1100px){
    .about-right p{
        font-size: 30px;
    }
    .about-right ul li{
        font-size: 20px;
    }

}
@media (max-width:950px){
    .about-bottom-left .first{
        font-size: 25px;
    }
    .about-bottom-left .second{
        font-size: 25px;
    }
}
 @media (max-width:880px){
    .about-right p{
        font-size: 25px;
    }
    .about-right ul li{
        font-size: 18px;
    }
    .bottom-content{
        display: flex;
        flex-direction: column;
        padding: 80px;
    }
    .about-content {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center child elements horizontally */
        gap: 30px;
    }
    .image-container {
        margin-top: 0px;
        justify-content: center; /* Center contents inside the container */
        align-items: center; /* Center contents inside the container */
        display: flex; /* Ensure flexbox is applied for centering */
        padding: 0;
    }
    .image-container .big-image{
        width: 50vw;
        height: 50vw;
        object-fit: cover;  
    }
 }
@media (max-width:500px){
    .about {
        min-height: 70vh;
    }
    .about .heading {
    font-size: 30px;
    }
    .about-right p{
        font-size: 20px;
    }
    .about-right ul li{
        font-size: 15px;
    }
    .image-container{
        margin-top: 0px;
    }
}
@media (max-width:430px){
    .about-bottom-left .first{
        font-size: 20px;
    }
    .about-bottom-left .second{
        font-size: 20px;
    }
    .btn3{
        font-size: 15px;
    }
}